@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap);
html {
  background: #11222E;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #2f3135;
}

